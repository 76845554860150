import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
// import PropTypes from 'prop-types';
import { CSpinner, useColorModes } from '@coreui/react';
import './scss/style.scss';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Home = React.lazy(() => import('./views/pages/home/Home'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const SurveyStart = React.lazy(() => import('./views/pages/survey/SurveyStart'));

// 로그인 여부를 확인하는 커스텀 훅
const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  React.useEffect(() => {
    // sessionStorage에서 userid를 가져옴
    const userid = sessionStorage.getItem('userid');
    //console.log('userId:', userid);
    // userid가 있으면 로그인 상태로 설정
    if (userid) {
      setIsAuthenticated(true);
    }
  }, []);

  return isAuthenticated;
};

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme');
  const storedTheme = useSelector((state) => state.theme);

  let storedUserId = sessionStorage.getItem('userid');
  //console.log('storedUserId: ', storedUserId);

  //const isAuthenticated = useAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0];
    if (theme) {
      setColorMode(theme);
    }

    if (isColorModeSet()) {
      return;
    }

    setColorMode(storedTheme);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Router>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route path="/" name="Home" element={<Home />} />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route exact path="/survey" name="Login Page" element={<SurveyStart />} />
          <Route exact path="/console/*" name="console" element={<DefaultLayout />}>
            <Route path="theme" name="LoggedIn" element={<DefaultLayout />} />
            <Route path="surveylist" name="List" element={<DefaultLayout />} />
            {/* <Route path="SC/:id" name="Modify" element={<DefaultLayout />} /> */}
          </Route>
          <Route exact path="*" name="Page 404" element={<Page404 />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
